/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 62.0.22-v202408071639
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqNotificationConfigurationsApiClass {
 
   constructor() {}

  /**
   * @summary Delete a notification configuration
   * @param {string} id - The Seeq ID of the notifiable item
   */
  public deleteNotificationConfiguration(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/notification-configurations/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Get a list of condition notifications
   * @param {Array<string>} [creatorIds] - Filter items to only those created by the specified user IDs
   * @param {string} [nameSearch] - Filter the items to those whose name match any part of the specified string
   * @param {string} [workbookSearch] - Filter the items to those whose condition are scoped to workbooks that match any part of the specified string
   * @param {Array<string>} [conditionIds] - Filter items to only those that are monitoring one of the specified conditions as specified by the list of IDs
   * @param {boolean} [enabledFilter] - Filter items by their enabled status
   * @param {number} [offset=0] - Whether to filter items to only users who are enabled.
   * @param {number} [limit=40] - The pagination limit, the total number of items that will be returned in this page of results
   * @param {string} [sortField=Name] - Order the items by either name or createdAt
   * @param {boolean} [sortAsc=true] - Sorts the sort field in ascending order when true and descending when false
   */
  public getConditionNotifications(
    {
      creatorIds,
      nameSearch,
      workbookSearch,
      conditionIds,
      enabledFilter,
      offset,
      limit,
      sortField,
      sortAsc
    } : {
      creatorIds?: Array<string>,
      nameSearch?: string,
      workbookSearch?: string,
      conditionIds?: Array<string>,
      enabledFilter?: boolean,
      offset?: number,
      limit?: number,
      sortField?: SortFieldEnum,
      sortAsc?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/notification-configurations/condition-monitors`,

      params: omitBy({
        ['creatorIds']: creatorIds,
        ['nameSearch']: nameSearch,
        ['workbookSearch']: workbookSearch,
        ['conditionIds']: conditionIds,
        ['enabledFilter']: enabledFilter,
        ['offset']: offset,
        ['limit']: limit,
        ['sortField']: sortField,
        ['sortAsc']: sortAsc
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionNotificationOutputListV1>;
  }

  /**
   * @summary Get a notification configuration
   * @param {string} id - The Seeq ID of the notifiable item
   */
  public getNotificationConfiguration(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/notification-configurations/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NotificationConfigurationOutputV1>;
  }

  /**
   * @summary Create or update a notification configuration on a condition monitor
   * @param {string} id - The Seeq ID of the condition monitor
   */
  public setNotificationConfigurationForConditionMonitor(
    body: models.ConditionMonitorNotificationConfigurationInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/notification-configurations/condition-monitors/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NotificationConfigurationOutputV1>;
  }

  /**
   * @summary Create or update a notification configuration on a report
   * @param {string} id - The Seeq ID of the report
   */
  public setNotificationConfigurationForReport(
    body: models.ReportNotificationConfigurationInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/notification-configurations/reports/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NotificationConfigurationOutputV1>;
  }

  /**
   * @summary Unsubscribe the current user from the notification on the specified item
   * @param {string} id - The ID of the item to unsubscribe from
   */
  public unsubscribeFromItem(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/notification-configurations/unsubscribe/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

}

export enum SortFieldEnum {
    Name = 'Name' as any,
    CreatedAt = 'CreatedAt' as any,
    Workbook = 'Workbook' as any
}

export const sqNotificationConfigurationsApi = new sqNotificationConfigurationsApiClass();
